// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-rich-text-page-js": () => import("./../src/components/Templates/RichTextPage.js" /* webpackChunkName: "component---src-components-templates-rich-text-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-overdraft-coverage-js": () => import("./../src/pages/overdraft-coverage.js" /* webpackChunkName: "component---src-pages-overdraft-coverage-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

